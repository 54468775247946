<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-alert
        :border="'left'"
        :icon="data.payload.icon"
        :type="data.payload.type"
        :elevation="hover ? 8 : 0"
        class="notification-item"
        max-width="360"
        :width="isMobileDevice ? null : 360"
      >
        <!-- Show the notification message here -->
        <div class="text-subtitle-2">
          {{ getTextFromHTML(data.payload.text) }}
        </div>

        <!-- Show the notification action here -->
        <div
          v-if="data.payload.button"
          class="mt-3 d-flex justify-end"
        >
          <v-btn
            color="white"
            @click="callAction"
            text
          >
            {{ data.payload.button.text }}
          </v-btn>
        </div>

        <!-- Show the icon to destroy this item -->
        <div
          class="notification-item__close white"
          :class="{ visible: hover }"
        >
          <v-icon
            large
            color="red"
            @click="destroySelf"
          >
            cancel
          </v-icon>
        </div>
      </v-alert>
    </template>
  </v-hover>
</template>

<script>
// Export the SFC
export default {
  // Name of the component
  name: "NotificationItem",

  // Accept incoming data from parent
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  // Define local method functions
  methods: {
    /**
     * Remove the notification from the stack
     *
     * @returns {void}
     */
    destroySelf() {
      this.$store.dispatch("notifications/remove", this.data.key)
    },

    /**
     * If there's a function defined, call it
     *
     * @returns {void}
     */
    callAction() {
      // Call the callback function
      if (typeof this.data.payload?.button?.action === "function") {
        this.data.payload.button.action()

        // Finally kill self
        this.destroySelf()
      }
    }
  }
}
</script>

<style lang="stylus">
.notification-item
  position relative

  &__close
    position absolute
    top -0.8em
    right -0.8em
    z-index 1000
    border-radius 100%
    transition all 0.1s ease-in
    opacity 0

    &.visible
      opacity 1

    &:hover
      transform scale(1.1) rotate(90deg)
</style>
